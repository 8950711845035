<template>
  <div>
    <slot name="header" />
    <div class="container mx-auto pb-12 max-w-sm">
      <div class="my-8">
        <image-upload
          @update="handleUpdate"
          @preview="handlePreview"
          placeholder="Last opp bilde av din ID" />
        <div v-if="formData.photoId">
          <success-message>
            ID ble lastet opp.
          </success-message>
        </div>
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    setFormData: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },

  data () {
    return {
      previewImage: null
    }
  },

  methods: {
    handleUpdate (value) {
      this.setFormData("photoId", value.uuid)
    },

    handlePreview (value) {
      this.previewImage = value
    }
  },

  computed: {
    modelErrors () {
      return validate.single(this.formData.photoId, {
        presence: true,
        type: "string"
      })
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
